'use client';

import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import useEnvironment from './Environment';
import { isRemoteEnvironment } from '../lib/environment';

import { datadogLogs } from '@datadog/browser-logs';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Logger {
  debug: (...args: any) => void;
  info: (...args: any) => void;
  warn: (...args: any) => void;
  error: (...args: any) => void;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const LoggingContext = createContext<Logger>(console);

export function LoggingProvider({ children }: { children: ReactNode }) {
  const config = useEnvironment();
  const loggerRef = useRef<Logger>(console);

  useEffect(() => {
    if (!config || !isRemoteEnvironment(config)) return;
    datadogLogs.setGlobalContextProperty('service', config.natoma.rootDomain);
    datadogLogs.setGlobalContextProperty('env', config.natoma.environment);
    loggerRef.current = datadogLogs.logger;
  }, [config]);

  return (
    <LoggingContext.Provider value={loggerRef.current}>
      {children}
    </LoggingContext.Provider>
  );
}

export default function useLogger() {
  return useContext(LoggingContext);
}
