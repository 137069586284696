'use client';

import React from 'react';
import { Descope } from '@descope/nextjs-sdk';
import useLogger from '../providers/Logging';
import { useRouter } from 'next/navigation';
import useEnvironment from '../providers/Environment';

/**
 * Props for the NatomaDescope component.
 */
type NatomaDescopeProps = {
  flowId: string;
  setStepped?: React.Dispatch<React.SetStateAction<boolean>>;
  tenant?: string;
  onSuccess?: () => void; // optional callback to run on successful completion,
  onReady?: () => void; // optional callback when screen is ready to display
};

const NatomaUnauthorizedMessage = 'User is not in the tenant';

export default function NatomaDescope({
  flowId,
  tenant,
  setStepped,
  onSuccess,
  onReady
}: NatomaDescopeProps) {
  const logger = useLogger();
  const router = useRouter();
  const { natoma } = useEnvironment();

  return (
    <Descope
      debug={process.env.NEXT_PUBLIC_DESCOPE_DEBUG_MODE === 'true'}
      flowId={flowId}
      tenant={tenant}
      onError={(e: Event) => {
        logger.error(e);
      }}
      onReady={onReady}
      onSuccess={onSuccess}
      logger={{
        debug: (title: string, description: string) => {
          logger.debug(title, description);
        },
        // work-around for ensuring the button only renders on first page
        info: (
          title: string,
          description: string,
          state: { stepId: string }
        ) => {
          if (setStepped && state.stepId !== '0') {
            setStepped(true);
          }
          logger.info(title, description);
        },
        warn: (title: string, description: string) => {
          logger.warn(title, description);
        },
        error: (title: string, description: string) => {
          if (title === NatomaUnauthorizedMessage) {
            const httpRegex = /^https?:\/\//;
            router.push(
              httpRegex.test(natoma.rootDomain)
                ? natoma.rootDomain
                : `https://${natoma.rootDomain}`
            );
          } else {
            logger.error(title, description);
          }
        }
      }}
    />
  );
}
